import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import {Store} from "@ngrx/store";
import {ActionAuthLogout, AppState, NotificationService} from "@app/core";
import {Router} from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class RoleService {
  baseURL: string = environment.baseURL;
  header: any;

  constructor(private http: HttpClient,
              private store: Store<AppState>,
              private router: Router,
              private notification: NotificationService) {}

  /*
    Method to set the headers in the APIs call of this file
  */
  createHeader() {
    let hours = 1;
    let now = new Date().getTime();
    let setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {
      localStorage.setItem('setupTime', now.toString())
    } else {
      if ( now - Number(setupTime) > hours*60*60*1000) {
        this.notification.error('Your session has expired. Please login again');
        this.store.dispatch(new ActionAuthLogout());
        localStorage.clear();
        this.router.navigate(["login"]);
      }
    }
    return (this.header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token")
    }));
  }

  /*
    API call to get all the roles present in the database
  */
  getAllRolesList(roleData): Observable<any> {
    return this.http.get(this.baseURL + 'role' + '?&pattern=' + roleData.searchPattern,  {
      headers: this.createHeader()
    });
  }

  addRole(data): Observable<any> {
    return this.http.post(this.baseURL + "role/add", data, {
      headers: this.createHeader()
    });
  }

  editRole(roleId, data): Observable<any> {
    return this.http.put(this.baseURL + "role/edit/" + roleId, data, {
      headers: this.createHeader()
    });
  }

  getSingleRole(roleId): Observable<any> {
    return this.http.get(this.baseURL + "role/" + roleId, {
      headers: this.createHeader()
    });
  }

  getDashboardRefreshToken(data): Observable<any> {
    return this.http.put(
        this.baseURL + 'users/refreshToken', data, {
          headers: this.createHeader()
        });
  }
}

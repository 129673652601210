import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";

import { SharedModule } from "@app/shared";

import { settingsReducer } from "./settings.reducer";
import { SettingsEffects } from "./settings.effects";
import { SettingsContainerComponent } from "./components/settings-container.component";

@NgModule({
  imports: [
    SharedModule,
    StoreModule.forFeature("settings", settingsReducer),
    EffectsModule.forFeature([SettingsEffects])
  ],
  declarations: [SettingsContainerComponent]
})
export class SettingsModule {}

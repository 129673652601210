import { NgModule, Optional, SkipSelf, ErrorHandler } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {
  StoreRouterConnectingModule,
  RouterStateSerializer
} from "@ngrx/router-store";

import { environment } from "@env/environment";

import { httpInterceptorProviders } from "./http-interceptors";
import { LocalStorageService } from "./local-storage/local-storage.service";
import { AuthEffects } from "./auth/auth.effects";
import { AuthGuardService, AdminService, NonLoggedInService, AdminHospitalAdminService, ManufacturerService, HospitalAdminService, LoggedInService } from "./auth/auth-guard.service";
import { AnimationsService } from "./animations/animations.service";
import { TitleService } from "./title/title.service";
import { reducers, metaReducers } from "./core.state";
import { AppErrorHandler } from "./error-handler/app-error-handler.service";
import { CustomSerializer } from "./router/custom-serializer";
import { NotificationService } from "./notifications/notification.service";
// import { GoogleAnalyticsEffects } from './google-analytics/google-analytics.effects';
import { SpinnerService } from "@app/core/spinner/spinner.service";

@NgModule({
  imports: [
    // angular
    CommonModule,
    HttpClientModule,

    // ngrx
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([AuthEffects]),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
          name: "TechM"
        }),

    // 3rd party
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [],
  providers: [
    NotificationService,
    LocalStorageService,
    AuthGuardService,
    AdminService,
    NonLoggedInService,
    AdminHospitalAdminService,
    ManufacturerService,
    HospitalAdminService,
    LoggedInService,
    AnimationsService,
    httpInterceptorProviders,
    TitleService,
    SpinnerService,
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: RouterStateSerializer, useClass: CustomSerializer }
  ],
  exports: [TranslateModule]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error("CoreModule is already loaded. Import only in AppModule");
    }
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `${environment.i18nPrefix}/assets/i18n/`,
    ".json"
  );
}

import { Injectable } from '@angular/core';
import {environment} from "@env/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {ActionAuthLogout, AppState, NotificationService} from "@app/core";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  baseURL: string = environment.baseURL;
  header: any;

  constructor(private http: HttpClient,
              private store: Store<AppState>,
              private router: Router,
              private notification: NotificationService) {}
  createHeader() {
    let hours = 1;
    let now = new Date().getTime();
    let setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {
      localStorage.setItem('setupTime', now.toString())
    } else {
      if ( now - Number(setupTime) > hours*60*60*1000) {
        this.notification.error('Your session has expired. Please login again');
        this.store.dispatch(new ActionAuthLogout());
        localStorage.clear();
        this.router.navigate(["login"]);
      }
    }
    return (this.header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token")
    }));
  }

  addTemplate(data): Observable<any> {
    return this.http.post(this.baseURL + "template/add", data, {
      headers: this.createHeader()
    });
  }

  editTemplate(templateId, data): Observable<any> {
    return this.http.put(this.baseURL + 'template/edit/' + templateId, data, {
      headers: this.createHeader()
    });
  }

  getAllTemplatesList(data): Observable<any> {
    return this.http.get(this.baseURL + 'template/fetch?&limit=' + data.limit + '&pageNumber=' + data.pageNo + "&status=" +
    data.status + '&pattern=' + data.searchPattern,
        {
          headers: this.createHeader()
        });
  }

  getAllTemplateSettingsById(templateId) {
    return this.http.get(
        this.baseURL + "template/fetch/" + templateId,
        {
          headers: this.createHeader()
        }
    );
  }

  applyTemplatesList(templateId, data): Observable<any> {
    return this.http.put(this.baseURL + "settings/assign/"  + templateId, data, {
      headers: this.createHeader()
    });
  }

  getTemplateInfoById(templateId) {
    return this.http.get(this.baseURL + "template/fetch/" + templateId, {
      headers: this.createHeader()
    });
  }

  updateTemplateStatus(data,templateId) {
    return this.http.put (this.baseURL + "template/updateStatus/" + templateId, data,{
      headers: this.createHeader()
    });
  }

  getDashboardRefreshToken(data): Observable<any> {
    return this.http.put(
        this.baseURL + 'users/refreshToken', data, {
          headers: this.createHeader()
        });
  }
}

const packageJson = require("../../package.json");

export const environment = {
  appName: "ScanEZ",
  envName: "PROD",
  production: true,
  test: false,
  i18nPrefix: "",
  versions: {
    app: packageJson.version,
    angular: packageJson.dependencies["@angular/core"],
    ngrx: packageJson.dependencies["@ngrx/store"],
    material: packageJson.dependencies["@angular/material"],
    bootstrap: packageJson.dependencies.bootstrap,
    rxjs: packageJson.dependencies.rxjs,
    ngxtranslate: packageJson.dependencies["@ngx-translate/core"],
    fontAwesome:
      packageJson.dependencies["@fortawesome/fontawesome-free-webfonts"],
    angularCli: packageJson.devDependencies["@angular/cli"],
    typescript: packageJson.devDependencies["typescript"],
    cypress: packageJson.devDependencies["cypress"]
  },
  // baseURL: 'http://192.168.254.31:4005/',
  // baseImgURL: 'http://192.168.255.133:3005',
  baseURL: 'https://3ls94kkgac.execute-api.us-east-1.amazonaws.com/dev/',
  baseImgURL: 'https://3ls94kkgac.execute-api.us-east-1.amazonaws.com/dev/',
  // baseURL: 'http://54.88.132.240:4005/',
  // baseImgURL: 'http://54.88.132.240:4005/',
  api_key: "d71a0600eb536f75c2d6de65f18628b5"
};

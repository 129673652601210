import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { SharedModule } from "@app/shared";
import { CoreModule } from "@app/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { EventsService } from "@app/events/events.service";
import { MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material";
import { AuthService } from "@app/service/auth.service";
import { UsersService } from "@app/service/user.service";
import { StaticModule } from "@app/static";
import { SettingsModule } from "@app/settings";
import { MatIconModule } from "@angular/material";
import { SpinnerModule } from "@app/shared/spinner/spinner.module";
import { SitesService } from "@app/service/site.service";
import { LocationService } from "@app/service/location.service";
import { DeviceService } from "@app/service/device.service";
import { ChangeLocationService } from "@app/service/change-location.service";
import { RolescreenService } from "@app/service/rolescreen.service";
import { RoleService } from "@app/service/role.service";
import { ConfigurationService } from "@app/service/configuration.service";
import { ReportsService } from "@app/service/reports.service";
import { MachinesService } from "@app/service/machines.service";
import { AreasService } from "@app/service/areas.service";
import { AmazingTimePickerService } from "amazing-time-picker";
import { SoftwareUpdatesService } from "@app/service/software-updates.service";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { DatePipe } from '@angular/common';
import {MyDatePickerModule} from "mydatepicker";
import {AngularMultiSelectModule} from "angular2-multiselect-dropdown";
import {NgxPrintModule} from "ngx-print";
import {ChangePasswordService} from "@app/service/change-password.service";

@NgModule({
    imports: [
        // core & shared
        CoreModule,
        SharedModule,
        BrowserAnimationsModule,
        BrowserModule,
        StaticModule,
        SettingsModule,
        // app
        AppRoutingModule,
        MatIconModule,
        SpinnerModule,
        NgxSmartModalModule.forRoot(),
        MyDatePickerModule,
        AngularMultiSelectModule,
        NgxPrintModule
    ],
  declarations: [AppComponent],
  providers: [
    EventsService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    UsersService,
    AuthService,
    SitesService,
    LocationService,
      ChangePasswordService,
    DeviceService,
    ChangeLocationService,
    RoleService,
    RolescreenService,
    ConfigurationService,
    ReportsService,
    MachinesService,
    AreasService,
    SoftwareUpdatesService,
    AmazingTimePickerService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import {Store} from "@ngrx/store";
import {ActionAuthLogout, AppState, NotificationService} from "@app/core";
import {Router} from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class SitesService {
  baseURL: string = environment.baseURL;
  header: any;

  constructor(private http: HttpClient,
              private store: Store<AppState>,
              private router: Router,
              private notification: NotificationService) {}

  /*
    Method to set the header in the API calls of this file
  */
  createHeader() {
    let hours = 1;
    let now = new Date().getTime();
    let setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {
      localStorage.setItem('setupTime', now.toString())
    } else {
      if ( now - Number(setupTime) > hours*60*60*1000) {
        this.notification.error('Your session has expired. Please login again');
        this.store.dispatch(new ActionAuthLogout());
        localStorage.clear();
        this.router.navigate(["login"]);
      }
    }
    return (this.header = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: localStorage.getItem("token")
    }));
  }

  /*
    API call for getting all the sites list present in the database of this application
  */
  getAllSitesList(data): Observable<any> {
    return this.http.get(
      this.baseURL +
        "site?&limit=" +
        data.limit +
        "&status=" +
        data.status +
        "&pageNumber=" +
        data.pageNumber +
        "&pattern=" +
        data.searchPattern +
        "&sort=" +
        data.sort,
      {
        headers: this.createHeader()
      }
    );
  }

  getdownDetails(data): Observable<any> {
    return this.http.get(
        this.baseURL +
        "public/getFileDownloadDetails?&id=" +
        data.id,
    );
  }


  getAllCustomerList(data): Observable<any> {
    return this.http.get(
        this.baseURL +
        "webApp/fetchCustomers?&limit=" +
        data.limit +
        "&pageNumber=" +
        data.pageNumber +
        "&status=" +
        data.status +
        "&pattern=" +
        data.pattern,
        {
          headers: this.createHeader()
        }
    );
  }

  /*
    API call for adding a new site
  */
  addSite(data): Observable<any> {
    return this.http.post(this.baseURL + "site/add", data, {
      headers: this.createHeader()
    });
  }


  authorizedPin(data): Observable<any> {
    return this.http.post(this.baseURL + "public/authorizeFileByPin", data, {
    });
  }


  /*
      API call for editing the site and submit the latest information
    */
  editSite(siteId, data): Observable<any> {
    return this.http.put(this.baseURL + "site/edit/" + siteId, data, {
      headers: this.createHeader()
    });
  }

  getSingleSite(siteId): Observable<any> {
    return this.http.get(this.baseURL + "site/find/" + siteId, {
      headers: this.createHeader()
    });
  }

  deleteSite(sitename, data): Observable<any> {
    return this.http.put(
        this.baseURL + "site/updateStatus/" + sitename,
        data,
        {
          headers: this.createHeader()
        }
    );
  }

  deleteFaxSite(sitename): Observable<any> {
    return this.http.post(
        this.baseURL + "webApp/createOrDeleteSrFaxSetting" , sitename,
        {
          headers: this.createHeader()
        }
    );
  }

  getDashboardRefreshToken(data): Observable<any> {
    return this.http.put(
        this.baseURL + 'users/refreshToken', data, {
          headers: this.createHeader()
        });
  }

  generateAPIKey(data): Observable<any> {
    return this.http.post(
        this.baseURL +
        'webApp/createApiKey' , data,
        {
          headers: this.createHeader()
        }
    );
  }

  getAPIKeys(data): Observable<any> {
    return this.http.post(
        this.baseURL +
        'webApp/fetchApiKeys' , data,
        {
          headers: this.createHeader()
        }
    );
  }

  enableDisableKey(data): Observable<any> {
    return this.http.put(
        this.baseURL +
        'webApp/enableOrDisableApi' , data,
        {
          headers: this.createHeader()
        }
    );
  }

  deleteApiKey(rowId): Observable<any> {
    return this.http.delete(
        this.baseURL +
        'webApp/deleteApiKey/' + rowId,
        {
          headers: this.createHeader()
        }
    );
  }

  deleteDownloadFile(rowId): Observable<any> {
    return this.http.delete(
        this.baseURL +
        'public/deleteFileById/' + rowId,
    );
  }

  regenerateApiKey(rowId): Observable<any> {
    const data = {};
    return this.http.put(
        this.baseURL +
        'webApp/regenerateApiKey/' + rowId, data,
        {
          headers: this.createHeader()
        }
    );
  }
}

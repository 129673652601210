import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '@env/environment';
import {ActionAuthLogout, AppState, NotificationService} from "@app/core";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AreasService {
  baseURL: string = environment.baseURL;
  header: any;

  constructor(private http: HttpClient,
              private store: Store<AppState>,
              private router: Router,
              private notification: NotificationService) { }

  createHeader() {
    let hours = 1;
    let now = new Date().getTime();
    let setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {
      localStorage.setItem('setupTime', now.toString())
    } else {
      if ( now - Number(setupTime) > hours*60*60*1000) {
        this.notification.error('Your session has expired. Please login again');
        this.store.dispatch(new ActionAuthLogout());
        localStorage.clear();
        this.router.navigate(["login"]);
      }
    }
    return (this.header = new HttpHeaders({
      // api_key: environment.api_key,
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token")
    }));
  }

  getAllAreasList(data): Observable<any> {
    return this.http.get(
        this.baseURL + "area?&limit=" + data.limit + "&status=" +
        data.status +
        "&pageNumber=" +
        data.pageNumber +
        "&pattern=" +
        data.searchPattern +
        "&sort=" +
        data.sort,
        {
          headers: this.createHeader()
        }
    );
  }

  editArea(areaId, data): Observable<any> {
    return this.http.put(this.baseURL + "area/edit/" + areaId, data, {
      headers: this.createHeader()
    });
  }

  addArea(data): Observable<any> {
    return this.http.post(this.baseURL + "area/add", data, {
      headers: this.createHeader()
    });
  }

  getAreaInfoById(areaId): Observable<any> {
    return this.http.get(this.baseURL + "area/fetch/" + areaId, {
      headers: this.createHeader()
    });
  }

  getAllTypesList(typeData): Observable<any> {
    return this.http.get(this.baseURL + 'type/fetch/' + '?&pattern=' + typeData.searchPattern,  {
      headers: this.createHeader()
    });
  }

    getAllAreaBasedOnLocation(locationId) {
      return this.http.get(
          this.baseURL + 'area/getAreaByLocationId/' + locationId,
          {
            headers: this.createHeader()
          }
      );
    }
  getAreasBasedOnLocations(locations) {
    return this.http.put(
        this.baseURL + 'area/fetchAreasByLocationsIds', locations,
        {
          headers: this.createHeader()
        }
    );
  }
  deleteArea(areaname, data): Observable<any> {
    return this.http.put(
        this.baseURL + "area/updateStatus/" + areaname,
        data,
        {
          headers: this.createHeader()
        }
    );
  }

  getDashboardRefreshToken(data): Observable<any> {
    return this.http.put(
        this.baseURL + 'users/refreshToken', data, {
          headers: this.createHeader()
        });
  }
}

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuardService } from "@app/core";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full"
  },
  {
    path: "login",
    loadChildren: "app/sign-in/sign-in.module#SignInModule"
  },
  {
    path: "forgot-password",
    loadChildren:
      "app/forgot-password/forgot-password.module#ForgotPasswordModule"
  },
  {
    path: "change-password",
    loadChildren:
        "app/change-password/change-password.module#ChangePasswordModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "profile",
    loadChildren: "app/profile/profile.module#ProfileModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "dashboard",
    loadChildren: "app/dashboard/dashboard.module#DashboardModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "users",
    loadChildren: "app/users/users.module#UsersModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "set-password",
    loadChildren: "app/set-password/set-password.module#SetPasswordModule"
  },
  {
    path: "customers",
    loadChildren: "app/sites/sites.module#SitesModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "locations",
    loadChildren: "app/location/location.module#LocationModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "download",
    loadChildren: "app/download/download.module#DownloadModule",
  },
  {
    path: "areas",
    loadChildren: "app/areas/areas.module#AreasModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "machines",
    loadChildren: "app/machines/machines.module#MachinesModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "devices",
    loadChildren: "app/device/device.module#DeviceModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "schedule-events",
    loadChildren: "app/schedule-events/schedule-events.module#ScheduleEventsModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "change-location",
    loadChildren:
      "app/change-location/change-location.module#ChangeLocationModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "roles",
    loadChildren: "app/roles/roles.module#RolesModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "user-logs",
    loadChildren: "app/user-logs/user-logs.module#UserLogsModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "permissions",
    loadChildren: "app/rolescreen/rolescreen.module#RolescreenModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "internet-fax-service",
    loadChildren: "app/internet-fax-service/internet-fax-service.module#InternetFaxReportModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "fax-setting",
    loadChildren: "app/fax-settings/fax-settings.module#FaxSettingsModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "templates",
    loadChildren: "app/configuration/configuration.module#ConfigurationModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "executive-reports",
    loadChildren: "app/reports/reports.module#ReportsModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "export-csv",
    loadChildren: "app/export-csv/export-csv.module#ExportCsvModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "compare-report",
    loadChildren: "app/compare-report/compare-report.module#CompareReportModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "schedule-report",
    loadChildren: "app/schedule-report/schedule-report.module#ScheduleReportModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "session-report",
    loadChildren: "app/session-report/session-report.module#SessionReportModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "detail-summary-report",
    loadChildren: "app/detail-summary-report/detail-summary-report.module#DetailSummaryReportModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "translation-report",
    loadChildren: "app/translation-report/translation-report.module#TranslationReportModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "fax-detail-report",
    loadChildren: "app/fax-detail-report/fax-detail-report.module#FaxDetailReportModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "fax-summary-report",
    loadChildren: "app/fax-summary-report/fax-summary.module#FaxSummaryReportModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "compare-fax-usage-summary",
    loadChildren: "app/compare-fax-usage-summary/compare-fax-usage-summary.module#CompareFaxSummaryReportModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "transaction-summary-report",
    loadChildren: "app/transaction-summary-report/transaction-summary-report.module#TransactionSummaryReportModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "financial-report",
    loadChildren: "app/financial-report/financial-report.module#FinancialReportModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "software-updates",
    loadChildren: "app/software-updates/software-updates.module#SoftwareUpdatesModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "license-manager",
    loadChildren: "app/license-manager/license-manager.module#LicenseManagerModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "basic-reports",
    loadChildren: "app/basic-reports/basic-reports.module#BasicReportsModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "generate-api-key",
    loadChildren: "app/generate-api-key/generate-api-key.module#GenerateApiKeyModule",
    canActivate: [AuthGuardService]
  },
];

@NgModule({
  // useHash supports github.io demo page, remove in your app
  imports: [
    // RouteModule.forRoot(ROUTES, { preloadingStrategy: PreloadAllModules })
    RouterModule.forRoot(routes, {
      useHash: false,
      scrollPositionRestoration: "enabled"
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

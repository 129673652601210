import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import {ActionAuthLogout, AppState, NotificationService} from "@app/core";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class LocationService {
  baseURL: string = environment.baseURL;
  header: any;

  constructor(private http: HttpClient,
              private store: Store<AppState>,
              private router: Router,
              private notification: NotificationService) {}

  /*
    Method to set the header in the API calls of this file
  */
  createHeader() {
    let hours = 1;
    let now = new Date().getTime();
    let setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {
      localStorage.setItem('setupTime', now.toString())
    } else {
      if ( now - Number(setupTime) > hours*60*60*1000) {
        this.notification.error('Your session has expired. Please login again');
        this.store.dispatch(new ActionAuthLogout());
        localStorage.clear();
        this.router.navigate(["login"]);
      }
    }
    return (this.header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token')
    }));
  }

  /*
    API call for getting all the sites list present in the database of this application
  */
  getAllLocationList(data): Observable<any> {
    return this.http.get(
      this.baseURL +
        "location?&limit=" +
        data.limit +
        "&status=" +
        data.status +
        "&pageNumber=" +
        data.pageNumber +
        "&pattern=" +
        data.searchPattern +
        "&sort=" +
        data.sort,
      {
        headers: this.createHeader()
      }
    );
  }

  /*
     API call for editing the site and submit the latest information
   */
  editLocation(siteId, data): Observable<any> {
    return this.http.put(this.baseURL + "location/edit/" + siteId, data, {
      headers: this.createHeader()
    });
  }

  /*
   API call for adding a new site
 */
  addLocation(data): Observable<any> {
    return this.http.post(this.baseURL + "location/add", data, {
      headers: this.createHeader()
    });
  }

  /**
   * API to get all locations
   * @param siteId
   */
  getAllLocationBasedOnSite(siteId) {
    return this.http.get(
      this.baseURL + "location/getLocationBySiteId/" + siteId,
      {
        headers: this.createHeader()
      }
    );
  }
  getLocationsBasedOnSites(sites): Observable<any> {
    return this.http.put(
        this.baseURL + "location/fetch/LocationsBySiteIds", sites,
        {
          headers: this.createHeader()
        }
    );
  }
  getSingleLocation(locationId): Observable<any> {
    return this.http.get(this.baseURL + "location/" + locationId, {
      headers: this.createHeader()
    });
  }

  deleteLocation(locationname, data): Observable<any> {
    return this.http.put(
        this.baseURL + "location/updateStatus/" + locationname,
        data,
        {
          headers: this.createHeader()
        }
    );
  }

  getDashboardRefreshToken(data): Observable<any> {
    return this.http.put(
        this.baseURL + 'users/refreshToken', data, {
          headers: this.createHeader()
        });
  }
}

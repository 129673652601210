import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import {ActionAuthLogout, AppState, NotificationService} from "@app/core";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class ReportsService {
  baseURL: string = environment.baseURL;
  header: any;
  constructor(private http: HttpClient,
              private store: Store<AppState>,
              private router: Router,
              private notification: NotificationService) {}
  createHeader() {
    let hours = 1;
    let now = new Date().getTime();
    let setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {
      localStorage.setItem('setupTime', now.toString())
    } else {
      if ( now - Number(setupTime) > hours*60*60*1000) {
        this.notification.error('Your session has expired. Please login again');
        this.store.dispatch(new ActionAuthLogout());
        localStorage.clear();
        this.router.navigate(["login"]);
      }
    }
    return (this.header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token")
    }));
  }
  createReportHeader() {
    return (this.header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Basic c2FjaGluX21haGFwdXJlQHR1ZGlwLmNvbTp0dWRpcDEyMw=="
    }));
  }

  displayReport(data): Observable<any> {
    let hours = 1;
    let now = new Date().getTime();
    let setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {
      localStorage.setItem('setupTime', now.toString())
    } else {
      if ( now - Number(setupTime) > hours*60*60*1000) {
        this.notification.error('Your session has expired. Please login again');
        this.store.dispatch(new ActionAuthLogout());
        localStorage.clear();
        this.router.navigate(["login"]);
      }
    }
    return this.http.post("https://tusip.jsreportonline.net/api/report", data, {
      headers: this.createReportHeader(),
      responseType: "text"
    });
  }
  getBasicReports(data): Observable<any> {
    return this.http.post(this.baseURL + "reportInfo/add", data, {
      headers: this.createHeader()
    });
  }

  getInternetFaxReports(data): Observable<any> {
    return this.http.post(this.baseURL + "webApp/sendSrFaxNotification", data, {
      headers: this.createHeader()
    });
  }

  getFaxSettingReports(data): Observable<any> {
    return this.http.post(this.baseURL + "webApp/changeCronJobTime", data, {
      headers: this.createHeader()
    });
  }

  getResendFaxSetting(): Observable<any> {
    return this.http.get(this.baseURL + "webApp/fetchResendFaxSettings", {
      headers: this.createHeader()
    });
  }


  getBasicTrackingIdReports(data): Observable<any> {
    return this.http.get(this.baseURL + "reportInfo/fetch/" + data, {
      headers: this.createHeader()
    });
  }

  getBasicTrackingProcessedReports(data): Observable<any> {
    return this.http.get(this.baseURL + "reportInfo/fetchRenderedReport/" + data, {
      headers: this.createHeader()
    });
  }

  getBasicTrackingProcessedReportsURL(data): Observable<any> {
    return this.http.get(data, {
      headers: this.createHeader()
    });
  }

  getCSVExport(data): Observable<any> {
    return this.http.put(this.baseURL + "report/fetchCsvReport", data, {
      headers: this.createHeader()
    });
  }

  getExecutiveReports(data): Observable<any> {
    return this.http.put(this.baseURL + "report/executive", data, {
      headers: this.createHeader()
    });
  }

  getCompareReports(data): Observable<any> {
    return this.http.put(this.baseURL + "report/financialReportsComparison", data, {
      headers: this.createHeader()
    });
  }

  getSessionReports(data): Observable<any> {
    return this.http.put(this.baseURL + "report/session", data, {
      headers: this.createHeader()
    });
  }

  getScheduleReports(data): Observable<any> {
    return this.http.post(this.baseURL + "report/schedule", data, {
      headers: this.createHeader()
    });
  }

  getEditScheduleReports(data, id): Observable<any> {
    return this.http.put(this.baseURL + "report/editSchedule/" + id, data, {
      headers: this.createHeader()
    });
  }

  getFetchScheduleReports(data): Observable<any> {
    return this.http.get(this.baseURL + "report/fetch?&limit=" + data.limit + "&pageNumber=" + data.pageNumber, {
      headers: this.createHeader()
    });
  }


  getdisableEnableSchedule(report, data): Observable<any> {
    return this.http.put(
        this.baseURL + "report/disableEnableSchedule/" + report,
        data,
        {
          headers: this.createHeader()
        }
    );
  }

  getReportsDetailsByID(data): Observable<any> {
    return this.http.get(this.baseURL + "report/fetchScheduleById/" + data.id, {
      headers: this.createHeader()
    });
  }

  getTransactionReports(data): Observable<any> {
    return this.http.put(this.baseURL + "report/detailedSiteSummary", data, {
      headers: this.createHeader()
    });
  }

  getExecutiveHTMLReports(data): Observable<any> {
    return this.http.put(this.baseURL + "report/detailedSiteSummary", data, {
      headers: this.createHeader()
    });
  }

  getExecutiveFinancialReports(data): Observable<any> {
    return this.http.put(this.baseURL + "report/dateSiteSummary", data, {
      headers: this.createHeader()
    });
  }

  getDetailReports(data): Observable<any> {
    return this.http.put(this.baseURL + "report/fetchDetailedSummary", data, {
      headers: this.createHeader()
    });
  }

  getTranslationReports(data): Observable<any> {
    return this.http.put(this.baseURL + "report/fetchDetailedSummary", data, {
      headers: this.createHeader()
    });
  }

  getFaxSummaryReports(data): Observable<any> {
    return this.http.put(this.baseURL + "report/faxReport", data, {
      headers: this.createHeader()
    });
  }

  getAllFaxNumber(data): Observable<any> {
    return this.http.put(this.baseURL + "site/username", data, {
      headers: this.createHeader()
    });
  }

  getFaxDetailReports(data): Observable<any> {
    return this.http.put(this.baseURL + "report/faxDetailReport", data, {
      headers: this.createHeader()
    });
  }

  getDropdownData(): Observable<any> {
    return this.http.get(this.baseURL + "staticData/fetch", {
      headers: this.createHeader()
    });
  }

  getDashboardRefreshToken(data): Observable<any> {
    return this.http.put(
        this.baseURL + 'users/refreshToken', data, {
          headers: this.createHeader()
        });
  }
}

import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { ActionAuthLogout, AppState, NotificationService } from "@app/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class MachinesService {
  baseURL: string = environment.baseURL;
  header: any;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private router: Router,
    private notification: NotificationService
  ) {}

  createHeader() {
    let hours = 1;
    let now = new Date().getTime();
    let setupTime = localStorage.getItem("setupTime");
    if (setupTime == null) {
      localStorage.setItem("setupTime", now.toString());
    } else {
      if (now - Number(setupTime) > hours * 60 * 60 * 1000) {
        this.notification.error("Your session has expired. Please login again");
        this.store.dispatch(new ActionAuthLogout());
        localStorage.clear();
        this.router.navigate(["login"]);
      }
    }
    return (this.header = new HttpHeaders({
      // api_key: environment.api_key,
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token")
    }));
  }

  getAllMachinesList(data): Observable<any> {
    return this.http.get(
      this.baseURL +
        "machine/fetchMachineWithFilters?limit=" +
        data.limit +
        "&status=" +
        data.status +
        "&pageNumber=" +
        data.pageNumber +
        "&pattern=" +
        data.searchPattern +
        "&sort=" +
        data.sort +
        "&siteId=" +
        data.siteId +
        "&dateFrom=" +
        data.dateFrom +
        "&dateTo=" +
        data.dateTo +
        "&timeZone=" +
        data.timezone +
        "&locationId=" +
        data.locationId +
        "&areaId=" +
        data.areaId,
      {
        headers: this.createHeader()
      }
    );
  }

  editMachine(machineId, data): Observable<any> {
    return this.http.put(this.baseURL + "machine/edit/" + machineId, data, {
      headers: this.createHeader()
    });
  }

  addMachine(data): Observable<any> {
    return this.http.post(this.baseURL + "machine/add", data, {
      headers: this.createHeader()
    });
  }

  getMachineInfoById(machineId): Observable<any> {
    return this.http.get(this.baseURL + "machine/get/" + machineId, {
      headers: this.createHeader()
    });
  }

  getsettingsByVersionNumber(versionNumber): Observable<any> {
    return this.http.get(this.baseURL + "webApp/settingsByVersionNumber?versionNumber=" + versionNumber, {
      headers: this.createHeader()
    });
  }

  getAllUnassignedMachineBasedOnLocation(locationId) {
    return this.http.get(
      this.baseURL + "machine/fetchUnassignedMachines/" + locationId,
      {
        headers: this.createHeader()
      }
    );
  }

  getSideList(data): Observable<any> {
    return this.http.get(
      this.baseURL +
        "site/fetchSitesWithLocationAndAreas?&limit=" +
        data.limit +
        "&pageNumber=" +
        data.pageNumber +
        "&site=" +
        data.site +
        "&location=" +
        data.location +
        "&area=" +
        data.area +
        "&machine=" +
        data.machine +
        "&status=" +
        data.status,
      {
        headers: this.createHeader()
      }
    );
  }

  getAllMachineBasedOnArea(areaId) {
    return this.http.get(
      this.baseURL + "machine/fetchMachineByAreaId/" + areaId,
      {
        headers: this.createHeader()
      }
    );
  }
  getMachinesBasedOnAreas(areas) {
    return this.http.put(this.baseURL + "area/fetchMachinesByAreaIds", areas, {
      headers: this.createHeader()
    });
  }
  getMachinesBySiteId(siteId) {
    return this.http.get(
      this.baseURL + "machine/fetchAllMachineBySiteId/" + siteId,
      {
        headers: this.createHeader()
      }
    );
  }

  deleteMachine(machinename, data): Observable<any> {
    return this.http.put(
      this.baseURL + "machine/updateStatus/" + machinename,
      data,
      {
        headers: this.createHeader()
      }
    );
  }

  getSettingsInfoByID(data): Observable<any> {
    return this.http.get(this.baseURL + "webApp/fetch/" + data.machineId, {
      headers: this.createHeader()
    });
  }

  getAllDropdownsList() {
    return this.http.get(this.baseURL + "staticData/fetchDropDown", {
      headers: this.createHeader()
    });
  }

  assignSettingsToMachines(data): Observable<any> {
    return this.http.put(
      this.baseURL + "webApp/editIndividualSetting" + "",
      data,
      {
        headers: this.createHeader()
      }
    );
  }

  scheduleSettingsMachines(data): Observable<any> {
    return this.http.put(
      this.baseURL + "webApp/editIndividualSetting" + "",
      data,
      {
        headers: this.createHeader()
      }
    );
  }

  getDashboardRefreshToken(data): Observable<any> {
    return this.http.put(this.baseURL + "users/refreshToken", data, {
      headers: this.createHeader()
    });
  }
}

import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import {ActionAuthLogout, AppState, NotificationService} from "@app/core";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class DeviceService {
  baseURL: string = environment.baseURL;
  header: any;

  constructor(private http: HttpClient,
              private store: Store<AppState>,
              private router: Router,
              private notification: NotificationService) {}

  /*
  Method to set the header in the API calls of this file
*/
  createHeader() {
    let hours = 1;
    let now = new Date().getTime();
    let setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {
      localStorage.setItem('setupTime', now.toString())
    } else {
      if ( now - Number(setupTime) > hours*60*60*1000) {
        this.notification.error('Your session has expired. Please login again');
        this.store.dispatch(new ActionAuthLogout());
        localStorage.clear();
        this.router.navigate(["login"]);
      }
    }
    return (this.header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token")
    }));
  }

  /*
   API call for getting all the sites list present in the database of this application
 */
  getAllDeviceList(data): Observable<any> {
    return this.http.get(
      this.baseURL +
        "device/getDevicesInformationWithFilters?&limit=" +
        data.limit +
        "&status=" +
        data.status +
        "&pageNumber=" +
        data.pageNumber +
        "&pattern=" +
        data.searchPattern +
        "&sort=" +
        data.sort +
        "&siteId=" +
        data.siteId +
        "&locationId=" +
        data.locationId +
        "&areaId=" +
        data.areaId,
      {
        headers: this.createHeader()
      }
    );
  }

  // @ts-ignore
  editDevice(device, data): Observable<any> {
    return this.http.put(this.baseURL + "device/edit/" + device, data, {
      headers: this.createHeader()
    });
  }

  // @ts-ignore
  addDevice(data): Observable<any> {
    return this.http.post(this.baseURL + "device/add", data, {
      headers: this.createHeader()
    });
  }

  getAllDeviceBasedOnLocation(locationId) {
    return this.http.get(
      this.baseURL + "device/deviceByLocationId/" + locationId,
      {
        headers: this.createHeader()
      }
    );
  }

  getAllSchedule(data) {
    return this.http.get(
        this.baseURL + "webApp/scheduleEvent?&limit=" +
        data.limit +
        "&pageNumber=" +
        data.pageNumber +
        "&timeZone=" +
        data.timeZone +
        "&type=" +
        data.type,
        {
          headers: this.createHeader()
        }
    );
  }

  getSingleDevice(deviceId): Observable<any> {
    return this.http.get(this.baseURL + "device/fetch/" + deviceId, {
      headers: this.createHeader()
    });
  }

  getAllUnassignedDeviceBasedOnLocation(locationId) {
    return this.http.get(
        this.baseURL + "device/fetchUnassignedDevices/" + locationId,
        {
          headers: this.createHeader()
        }
    );
  }

  getAreaByLocationId(locationId) {
    return this.http.get(
        this.baseURL + "area/getAreaByLocationId/" + locationId,
        {
          headers: this.createHeader()
        }
    );
  }

  deleteDevice(devicename, data): Observable<any> {
    return this.http.put(
        this.baseURL + "device/updateStatus/" + devicename,
        data,
        {
          headers: this.createHeader()
        }
    );
  }

  getDashboardRefreshToken(data): Observable<any> {
    return this.http.put(
        this.baseURL + 'users/refreshToken', data, {
          headers: this.createHeader()
        });
  }
}

import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";

import { selectIsAuthenticated } from "./auth.selectors";
import { AppState } from "../core.state";

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private store: Store<AppState>) {}

  canActivate(): Observable<boolean> {
    return this.store.pipe(select(selectIsAuthenticated));
  }
}

@Injectable()
export class AdminService implements CanActivate {
  canActivate() {
    if (localStorage.getItem('token') && localStorage.getItem('role') === 'admin') {
      return true;
    }
  }
}

@Injectable()
export class ManufacturerService implements CanActivate {
  constructor(private store: Store<AppState>) {}

  canActivate() {
    if (localStorage.getItem('token') && localStorage.getItem('role') === 'manufacturer') {
      return true;
    }
  }
}

@Injectable()
export class HospitalAdminService implements CanActivate {
  constructor(private store: Store<AppState>) {}

  canActivate() {
    if (localStorage.getItem('token') && localStorage.getItem('role') === 'user') {
      return true;
    }
  }
}

@Injectable()
export class NonLoggedInService implements CanActivate {
  constructor(private store: Store<AppState>) {}

  canActivate() {
    if ('localStorage.length ==== 0') {
      return true;
    }
  }
}

@Injectable()
export class LoggedInService implements CanActivate {
  constructor(private store: Store<AppState>) {}

  canActivate() {
    if ('localStorage.length!==0') {
      return true;
    }
  }
}

@Injectable()
export class AdminHospitalAdminService implements CanActivate {
  constructor(private store: Store<AppState>) {}

  canActivate() {
    if (localStorage.getItem('token') && (localStorage.getItem('role') === 'user' || localStorage.getItem('role') === 'admin')) {
      return true;
    }
  }
}

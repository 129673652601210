import browser from "browser-detect";
import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit
} from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";
import { AppConstant } from "@app/constants/app.constants";
import { NotificationService } from "@app/core";

import {
  ActionAuthLogin,
  ActionAuthLogout,
  routeAnimations,
  AppState,
  LocalStorageService,
  selectIsAuthenticated
} from "@app/core";
import { environment as env } from "@env/environment";

import {
  ActionSettingsChangeLanguage,
  ActionSettingsChangeAnimationsPageDisabled,
  selectEffectiveTheme,
  selectSettingsLanguage,
  selectSettingsStickyHeader
} from "./settings";
import { Router, ActivatedRoute } from "@angular/router";
import { EventsService } from "@app/events/events.service";

@Component({
  selector: "anms-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [routeAnimations]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  isProd = env.production;
  envName = env.envName;
  version = env.versions.app;
  year = new Date().getFullYear();
  navigation = [];
  navigationSideMenu = [];
  isAuthenticated$: Observable<boolean>;
  stickyHeader$: Observable<boolean>;
  language$: Observable<string>;
  theme$: Observable<string>;
  firstName = "";
  appHeader: String = "";
  manufacturerName: string;
  role: string;
  path: string;
  logo = require("../assets/img_ScanEZ_Final_LRez_onDark.png");
  isLoogedIn = false;
  rolesArray = [];
  rolesAddEdit = [];
  isPreview = {
    Dashboard: true,
    Users: false,
    Sites: false,
    Settings: false,
    Reports: false,
    Export: false,
    Notification: false,
    Devices: false,
    'Add/Edit User': false,
    'View User': false,
    'Add/Edit Roles': false,
    'View Roles': false,
    'Add/Edit Permissions': false,
    'View Permissions': false,
    'Add/Edit Site': false,
    'View Site': false,
    'Add/Edit Locations': false,
    'View Locations': false,
    'Add/Edit Devices': false,
    'View Devices': false,
    'Add/Edit Machines': false,
    'Read-only Schedule Events': false,
    'View Machines': false,
    'Add/Edit Areas': false,
    'View Areas': false,
    "Add/Edit Machine Configuration": false,
    "Read-only Machine Configuration": false,
    "Add/Edit Software-Updates": false,
    "View Software-Updates": false,
    "Basic-Reports": false,
    "Executive-Reports": false,
    "Compare Report": false,
    "Add/Edit Schedule Report": false,
    "Session Report": false,
    "Detail-Summary-Reports": false,
    "Translation-Reports": false,
    "Fax-Detail-Reports": false,
    "Fax-Summary-Reports": false,
    "Compare-Fax-Usage-Summary": false,
    "Transaction-Summary-Reports": false,
    "Financial-Report-Summary": false,
    "Add/Edit License-Manager": false,
    "Add/Edit Fax Setting": false,
    "Read-Only Fax Setting": false,
    "Add/Edit Resend Fax Time": false,
    "Read-Only Resend Fax Time": false,
    "View License-Manager": false,
    "Add/Edit Template": false,
    "View License-Template": false,
    "View Audit Log": false,
    "Read-only Export CSV": false,
    "Internet Fax Service": false,
    'Add/Edit API Key': false,
    'View API Key': false
  };


  constructor(
    private store: Store<AppState>,
    private storageService: LocalStorageService,
    private router: Router,
    private events: EventsService,
    private route: ActivatedRoute,
    private notification: NotificationService
  ) {
    this.events.appHeader.subscribe(item => {
      if (item) {
        this.appHeader = item;
      } else {
        this.appHeader = "";
      }
      setTimeout(() => {
        this.firstName = localStorage.getItem("userName");
      });
    });
  }

  private static isIEorEdgeOrSafari() {
    return ["ie", "edge", "safari"].includes(browser().name);
  }

  ngOnInit(): void {
    this.storageService.testLocalStorage();
    if (AppComponent.isIEorEdgeOrSafari()) {
      this.store.dispatch(
        new ActionSettingsChangeAnimationsPageDisabled({
          pageAnimationsDisabled: true
        })
      );
    }
    this.isAuthenticated$ = this.store.pipe(select(selectIsAuthenticated));
    this.isLoogedIn = localStorage.getItem("token") ? true : false;
    this.stickyHeader$ = this.store.pipe(select(selectSettingsStickyHeader));
    this.language$ = this.store.pipe(select(selectSettingsLanguage));
    this.theme$ = this.store.pipe(select(selectEffectiveTheme));
    setTimeout(() => {
      this.path = this.router.url;
    }, 500);
  }

  ngAfterViewInit(): void {
    this.events.appHeader.subscribe(item => {
      this.path = this.router.url;
      if (item) {
        this.appHeader = item;
      }
      this.isLoogedIn = localStorage.getItem("token") ? true : false;

      this.resetHeaderSettings();
      this.rolesArray = JSON.parse(localStorage.getItem("roleData"));
      this.rolesArray.forEach(res => {
        this.rolesAddEdit.push(res.name);
        switch (res.name.toLowerCase()) {
          case "reports":
            this.isPreview["Reports"] = true;
            break;
          case "basic report":
            this.isPreview["Basic-Reports"] = true;
            break;
          case "executive report":
            this.isPreview["Executive-Reports"] = true;
            break;
          case "compare report":
            this.isPreview["Compare Report"] = true;
            break;
          case "read-only schedule report":
            this.isPreview["Schedule-Report"] = true;
            localStorage.setItem("rolesAddEdit", res.name);
            break;
          case "add/edit schedule report":
            this.isPreview["Add/Edit Schedule Report"] = true;
            localStorage.setItem("rolesAddEdit", res.name);
            break;
          case "session report":
            this.isPreview["Session Report"] = true;
            break;
          case "detail summary report":
          this.isPreview["Detail-Summary-Reports"] = true;
          break;
          case "translation report":
            this.isPreview["Translation-Reports"] = true;
            break;
          case "fax detail report":
            this.isPreview["Fax-Detail-Reports"] = true;
            break;
          case "fax summary report":
            this.isPreview["Fax-Summary-Reports"] = true;
            break;
          case "compare fax usage summary":
            this.isPreview["Compare-Fax-Usage-Summary"] = true;
            break;
          case "financial detail report":
            this.isPreview["Transaction-Summary-Reports"] = true;
            break;
          case "financial report summary":
            this.isPreview["Financial-Report-Summary"] = true;
            break;
          case "read-only export csv":
            this.isPreview["Read-only Export CSV"] = true;
            break;
          case "internet fax service":
            this.isPreview["Internet Fax Service"] = true;
            break;
          case "users":
            this.isPreview["Users"] = true;
            break;
          case "customers":
            this.isPreview["Sites"] = true;
            break;
          case "export":
            this.isPreview["Export"] = true;
            break;
          case "notification":
            this.isPreview["Notification"] = true;
            break;
          case "dashboard":
            this.isPreview["Dashboard"] = true;
            break;
          case "settings":
            this.isPreview["Settings"] = true;
            break;
          case "devices":
            this.isPreview["Devices"] = true;
            break;
          case "add/edit user":
            this.isPreview["Add/Edit User"] = true;
            break;
          case "read-only user":
            this.isPreview["View User"] = true;
            break;
          case "add/edit roles":
            this.isPreview["Add/Edit Roles"] = true;
            break;
          case "read-only roles":
            this.isPreview["View Roles"] = true;
            break;
          case "add/edit permissions":
            this.isPreview["Add/Edit Permissions"] = true;
            break;
          case "read-only permissions":
            this.isPreview["View Permissions"] = true;
            break;
          case "add/edit customers":
            this.isPreview["Add/Edit Site"] = true;
            break;
          case "read-only customers":
            this.isPreview["View Site"] = true;
            break;
          case "add/edit locations":
            this.isPreview["Add/Edit Locations"] = true;
            break;
          case "read-only locations":
            this.isPreview["View Locations"] = true;
            break;
          case "read-only devices":
            this.isPreview["View Devices"] = true;
            break;
          case "add/edit devices":
            this.isPreview["Add/Edit Devices"] = true;
            break;
          case "add/edit machines":
            this.isPreview["Add/Edit Machines"] = true;
            break;
          case "read-only schedule events":
            this.isPreview["Read-only Schedule Events"] = true;
            break;
          case "read-only machines":
            this.isPreview["View Machines"] = true;
            break;
          case "add/edit areas":
            this.isPreview["Add/Edit Areas"] = true;
            break;
          case "read-only areas":
            this.isPreview["View Areas"] = true;
            break;
          case "add/edit machine configuration":
            this.isPreview["Add/Edit Machine Configuration"] = true;
            break;
          case "read-only machine configuration":
            this.isPreview["Read-only Machine Configuration"] = true;
            break;
          case "add/edit software updates":
            this.isPreview["Add/Edit Software-Updates"] = true;
            break;
          case "read-only software updates":
            this.isPreview["View Software-Updates"] = true;
            break;
          case "add/edit license manager":
            this.isPreview["Add/Edit License-Manager"] = true;
            break;
          case "read-only fax setting":
            this.isPreview["Read-Only Fax Setting"] = true;
            localStorage.setItem("faxAddEdit", res.name);
            break;
          case "add/edit fax setting":
            this.isPreview["Add/Edit Fax Setting"] = true;
            localStorage.setItem("faxAddEdit", res.name);
            break;
          case "read-only resend fax time":
            this.isPreview["Read-Only Resend Fax Time"] = true;
            localStorage.setItem("faxResendAddEdit", res.name);
            break;
          case "add/edit resend fax time":
            this.isPreview["Add/Edit Resend Fax Time"] = true;
            localStorage.setItem("faxResendAddEdit", res.name);
            break;
          case "read-only license manager":
            this.isPreview["View License-Manager"] = true;
            break;
          case "add/edit template":
            this.isPreview["Add/Edit Template"] = true;
            break;
          case "read-only template":
            this.isPreview["View Template"] = true;
            break;
          case "audit log":
            this.isPreview["View Audit log"] = true;
            break;
          case "add/edit generate api key":
            this.isPreview["Add/Edit API Key"] = true;
            break;
          case "read-only generate api key":
            this.isPreview["View API Key"] = true;
            break;
        }
      });
      this.navigation = [
        {
          link: "dashboard",
          label: "Dashboard",
          active: true,
          icon: "fa fa-dashboard",
          isPreviewLink: this.isPreview["Dashboard"]
        },
        {
          //link: "users",
          label: "Users",
          active: false,
          opened: true,
          icon: "fa fa-users",
          isPreviewLink: this.isPreview["Users"],
          subMenus: [
            {
              link: "users",
              url: "/users",
              label: "Users",
              parent: "users",
              icon: "fa fa-users",
              isPreviewLink: this.isPreview["Add/Edit User"] || this.isPreview["View User"]
            },
            {
              link: "roles",
              url: "/roles",
              label: "Roles",
              parent: "users",
              icon: "fa fa-user",
              isPreviewLink: this.isPreview["Add/Edit Roles"] || this.isPreview["View Roles"]
            },
            {
              link: "permissions",
              url: "/permissions",
              label: "Permissions",
              parent: "users",
              icon: "fa fa-universal-access",
              isPreviewLink: this.isPreview["Add/Edit Permissions"] || this.isPreview["View Permissions"]
            },
          ]
        },
        {
          //link: "export",
          label: "Export",
          active: false,
          opened: true,
          icon: "fa fa-file",
          isPreviewLink: this.isPreview["Export"],
          subMenus: [
            {
              link: "export-csv",
              url: "/export-csv",
              label: "CSV Export",
              parent: "export",
              icon: "fa fa-file",
              isPreviewLink: this.isPreview["Read-only Export CSV"]
            },
          ]
        },
        {
          //link: "export",
          label: "Notifications",
          active: false,
          opened: true,
          icon: "fa fa-bell",
          isPreviewLink: this.isPreview["Notification"],
          subMenus: [
            {
              link: "internet-fax-service",
              url: "/internet-fax-service",
              label: "Internet Fax Service",
              parent: "notifications",
              icon: "fa fa-fax",
              isPreviewLink: this.isPreview["Internet Fax Service"]
            },
          ]
        },
        {
          //link: "customers",
          label: "Customers",
          active: false,
          opened: true,
          icon: "fa fa-sitemap",
          isPreviewLink: this.isPreview["Sites"],
          subMenus: [
            {
              link: "customers",
              url: "/customers",
              label: "Customers",
              parent: "customers",
              icon: "fa fa-sitemap",
              isPreviewLink: this.isPreview["Add/Edit Site"] || this.isPreview["View Site"]
            },
            {
              link: "locations",
              url: "/locations",
              label: "Locations",
              parent: "customers",
              icon: "fa fa-map-marker",
              isPreviewLink: this.isPreview["Add/Edit Locations"] || this.isPreview["View Locations"]
            },
            {
              link: "areas",
              url: "/areas",
              label: "Areas",
              parent: "customers",
              icon: "fa fa-building",
              isPreviewLink: this.isPreview["Add/Edit Areas"] || this.isPreview["View Areas"]
            },
          ]
        },
        {
          //link: "devices",
          label: "Devices",
          active: false,
          opened: true,
          icon: "fa fa-tablet",
          isPreviewLink: this.isPreview["Devices"],
          subMenus: [
            {
              link: "devices",
              url: "/devices",
              label: "Devices",
              parent: "devices",
              icon: "fa fa-tablet",
              isPreviewLink: this.isPreview["Add/Edit Devices"] || this.isPreview["View Devices"]
            },
            {
              link: "machines",
              url: "/machines",
              label: "Machines",
              parent: "devices",
              icon: "material-icons",
              materialIcon: "scanner",
              isPreviewLink: this.isPreview["Add/Edit Machines"] || this.isPreview["View Machines"]
            },
            {
              link: "schedule-events",
              url: "/schedule-events",
              label: "Scheduled Events",
              parent: "devices",
              icon: "material-icons",
              materialIcon: "schedule",
              isPreviewLink: this.isPreview["Read-only Schedule Events"]
            },
            {
              link: "machines/settings-machine",
              url: "/machines/settings-machine",
              label: "Machine Configuration",
              parent: "devices",
              icon: "material-icons",
              materialIcon: "scanner",
              isPreviewLink: this.isPreview["Add/Edit Machine Configuration"] || this.isPreview["Read-only Machine Configuration"]
            }
          ]
        },
        {
          //link: "templates",
          label: "Settings",
          active: false,
          opened: true,
          icon: "fa fa-cog",
          isPreviewLink: this.isPreview["Settings"],
          subMenus: [
            {
              link: "fax-setting",
              url: "/fax-setting",
              label: "Fax Resend Setting",
              parent: "settings",
              icon: "fa fa fa-cog",
              isPreviewLink: this.isPreview["Read-Only Fax Setting"] || this.isPreview["Add/Edit Fax Setting"]
            },
            {
              link: "templates",
              url: "/templates",
              label: "Templates",
              parent: "settings",
              icon: "fa fa fa-cog",
              isPreviewLink: this.isPreview["Add/Edit Template"] || this.isPreview["View Template"]
            },
            {
              link: "software-updates",
              url: "/software-updates",
              label: "Software Updates",
              parent: "settings",
              icon: "fa fa-wrench",
              isPreviewLink: this.isPreview["Add/Edit Software-Updates"] || this.isPreview["View Software-Updates"]
            },
            {
              link: "license-manager",
              url: "/license-manager",
              label: "License Manager",
              parent: "settings",
              icon: "fa fa-universal-access",
              isPreviewLink: this.isPreview["Add/Edit License-Manager"] || this.isPreview["View License-Manager"]
            },
            {
              link: "generate-api-key",
              url: "/generate-api-key",
              label: "Generate API Key",
              parent: "settings",
              icon: "fa fa fa-key",
              isPreviewLink: this.isPreview["Add/Edit API Key"] || this.isPreview["View API Key"]
            }
          ]
        },
        {
          //link: "reports",
          label: "Reports",
          active: false,
          opened: true,
          icon: "fa fa-bar-chart",
          isPreviewLink: this.isPreview["Reports"],
          subMenus: [
            {
              link: "user-logs",
              url: "/user-logs",
              label: "Audit Log",
              parent: "reports",
              icon: "fa fa fa-bar-chart",
              isPreviewLink: this.isPreview["View Audit log"]
            },
            {
              link: "basic-reports",
              url: "/basic-reports",
              label: "Basic Report",
              parent: "reports",
              icon: "fa fa fa-bar-chart",
              isPreviewLink: this.isPreview["Basic-Reports"]
            },
            {
              link: "compare-fax-usage-summary",
              url: "/compare-fax-usage-summary",
              label: "Compare Fax Usage Summary",
              parent: "reports",
              icon: "fa fa fa-bar-chart",
              isPreviewLink: this.isPreview["Compare-Fax-Usage-Summary"]
            },
            {
              link: "compare-report",
              url: "/compare-report",
              label: "Compare Report",
              parent: "reports",
              icon: "fa fa fa-bar-chart",
              isPreviewLink: this.isPreview["Compare Report"]
            },
            {
              link: "detail-summary-report",
              url: "/detail-summary-report",
              label: "Detail Summary Report",
              parent: "reports",
              icon: "fa fa fa-bar-chart",
              isPreviewLink: this.isPreview["Detail-Summary-Reports"]
            },
            {
              link: "executive-reports",
              url: "/executive-reports",
              label: "Executive Report",
              parent: "reports",
              icon: "fa fa fa-bar-chart",
              isPreviewLink: this.isPreview["Executive-Reports"]
            },
            {
              link: "fax-detail-report",
              url: "/fax-detail-report",
              label: "Fax Detail Report",
              parent: "reports",
              icon: "fa fa fa-bar-chart",
              isPreviewLink: this.isPreview["Fax-Detail-Reports"]
            },
            {
              link: "fax-summary-report",
              url: "/fax-summary-report",
              label: "Fax Summary Report",
              parent: "reports",
              icon: "fa fa fa-bar-chart",
              isPreviewLink: this.isPreview["Fax-Summary-Reports"]
            },
            {
              link: "transaction-summary-report",
              url: "/transaction-summary-report",
              label: "Financial Detail Report",
              parent: "reports",
              icon: "fa fa fa-bar-chart",
              isPreviewLink: this.isPreview["Transaction-Summary-Reports"]
            },
            {
              link: "financial-report",
              url: "/financial-report",
              label: "Financial Summary Report",
              parent: "reports",
              icon: "fa fa fa-bar-chart",
              isPreviewLink: this.isPreview["Financial-Report-Summary"]
            },
            {
              link: "schedule-report",
              url: "/schedule-report",
              label: "Schedule Report",
              parent: "reports",
              icon: "fa fa fa-bar-chart",
              isPreviewLink: this.isPreview["Add/Edit Schedule Report"]
            },
            {
              link: "session-report",
              url: "/session-report",
              label: "Session Report",
              parent: "reports",
              icon: "fa fa fa-bar-chart",
              isPreviewLink: this.isPreview["Session Report"]
            },
            {
              link: "translation-report",
              url: "/translation-report",
              label: "Translation Report",
              parent: "reports",
              icon: "fa fa fa-bar-chart",
              isPreviewLink: this.isPreview["Translation-Reports"]
            }
          ]
        }
      ];
      localStorage.setItem("pagePermissions", JSON.stringify(this.isPreview));
      this.navigationSideMenu = [...this.navigation];
      this.manufacturerName = localStorage.getItem("manufacturerName");
      this.role = localStorage.getItem("role");
    });
  }

  test() {
    this.router.navigate(["settings"]);
  }

  resetHeaderSettings() {
    this.rolesArray = [];
    this.isPreview["Settings"] = false;
    this.isPreview["Reports"] = false;
    this.isPreview["Users"] = false;
    this.isPreview["Sites"] = false;
    this.isPreview["Export"] = false;
    this.isPreview["Read-only Export CSV"] = false;
    this.isPreview["Notification"] = false;
    this.isPreview["Internet Fax Service"] = false;
    this.isPreview["Dashboard"] = true;
    this.isPreview["Configuration"] = false;
    this.isPreview["Add/Edit User"] = false;
    this.isPreview["View User"] = false;
    this.isPreview["Add/Edit Roles"] = false;
    this.isPreview["View Roles"] = false;
    this.isPreview["Add/Edit Permissions"] = false;
    this.isPreview["View Permissions"] = false;
    this.isPreview["Add/Edit Site"] = false;
    this.isPreview["View Site"] = false;
    this.isPreview["Add/Edit Machines"] = false;
    this.isPreview["View Machines"] = false;
    this.isPreview["Devices"] = false;
    this.isPreview["Add/Edit Devices"] = false;
    this.isPreview["View Devices"] = false;
    this.isPreview["Add/Edit Locations"] = false;
    this.isPreview["View Locations"] = false;
    this.isPreview["Add/Edit Areas"] = false;
    this.isPreview["Views Areas"] = false;
    this.isPreview["Add/Edit Machine Configuration"] = false;
    this.isPreview["Read-only Machine Configuration"] = false;
    this.isPreview["Add/Edit Software-Updates"] = false;
    this.isPreview["View Software-Updates"] = false;
    this.isPreview["Add/Edit License-Manager"] = false;
    this.isPreview["Read-Only Fax Setting"] = false;
    this.isPreview["Add/Edit Fax Setting"] = false;
    this.isPreview["Read-only Resend Fax Time"] = false;
    this.isPreview["Add/Edit Resend Fax Time"] = false;
    this.isPreview["View License-Manager"] = false;
    this.isPreview["Add/Edit Template"] = false;
    this.isPreview["View Template"] = false;
    this.isPreview["Basic-Reports"] = false;
    this.isPreview["Executive-Reports"] = false;
    this.isPreview["Compare Report"] = false;
    this.isPreview["Schedule Reports"] = false;
    this.isPreview["Add/Edit Schedule Report"] = false;
    this.isPreview["Session Report"] = false;
    this.isPreview["Detail-Summary-Reports"] = false;
    this.isPreview["Translation-Reports"] = false;
    this.isPreview["Fax-Detail-Reports"] = false;
    this.isPreview["Fax-Summary-Reports"] = false;
    this.isPreview["Compare-Fax-Usage-Summary"] = false;
    this.isPreview["Transaction-Summary-Reports"] = false;
    this.isPreview["Financial-Report-Summary"] = false;
    this.isPreview["View Audit log"] = false;
    this.isPreview["Add/Edit API Key"] = false;
    this.isPreview["View API Key"] = false;
  }

  onLoginClick() {
    this.store.dispatch(new ActionAuthLogin());
    // let hours = 1; // Reset when storage is more than 24hours
    // let now = new Date().getTime();
    // console.log('now-------------->>>', now);
    // let setupTime = localStorage.getItem('setupTime');
    // console.log('setupTime-------------->>>', setupTime);
    // if (setupTime == null) {
    //   localStorage.setItem('setupTime', now)
    // } else {
    //   if ( now - setupTime > hours * 60 * 60 * 1000 ) {
    //     localStorage.clear();
    //     localStorage.setItem('setupTime', now);
    //   }
    // }
    this.router.navigate(["users"]);
  }

  onLogoutClick() {
    this.store.dispatch(new ActionAuthLogout());
    localStorage.clear();
    this.notification.success(AppConstant.LOGOUT_SUCCESS);
    this.router.navigate(["login"]);
  }

  onChangePassClick() {
    this.router.navigate(["change-password"]);
  }

  onProfileClick() {
    this.store.dispatch(new ActionAuthLogin());
    this.router.navigate(["profile"]);
  }

  navigateToDefaultPage() {
    switch (localStorage.getItem("role")) {
      case "admin":
        this.router.navigate(["users"]);
        break;
      default:
        this.router.navigate(["login"]);
        break;
    }
  }

  onSubmit() {
    this.store.dispatch(new ActionAuthLogin());
  }

  onLanguageSelect({ value: language }) {
    this.store.dispatch(new ActionSettingsChangeLanguage({ language }));
  }

  showChildMenu(menu, hoverType) {
    if (menu.subMenus && menu.subMenus.length) {
      menu.opened = hoverType !== "mouseHover";
    }
  }

  ngOnDestroy() {
    console.log("inside destroy metho:");
  }
}

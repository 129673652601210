import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@env/environment";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  baseURL: string = environment.baseURL;
  header: any;
  constructor(private http: HttpClient) {
    this.header = new HttpHeaders({
      // api_key: environment.api_key,
      "Content-Type": "application/json"
    });
  }

  /*
    Method to set the headers in the API calls
  */
  createHeader() {
    return new HttpHeaders({
      // api_key: environment.api_key,
      "Content-Type": "application/json",
      auth_token: localStorage.getItem("token")
    });
  }

  /*
    Method to hit the login API
  */
  login(credentials): Observable<any> {
    return this.http.post(this.baseURL + "public/login", credentials, {
      headers: this.header
    });
  }

  /*
    Method to hit the Forgot Password API
  */
  forgotPassword(userName): Observable<any> {
    return this.http.post(
      this.baseURL + "public/resetPassword",
      userName,
      {
        headers: this.header
      }
    );
  }

  /*
    Method to hit the logout API
  */
  logout(): Observable<any> {
    return this.http.post(
      this.baseURL + "account/logout",
      {},
      {
        headers: this.createHeader()
      }
    );
  }

  /*
    Mehtod to hit the forgot password when user submits the email
  */
  forgotPasswordEmail(data): Observable<any> {
    return this.http.post(this.baseURL + "users/forgotCredential", data, {
      headers: this.header
    });
  }

  getDashboardRefreshToken(data): Observable<any> {
    return this.http.put(
        this.baseURL + 'users/refreshToken', data, {
          headers: this.createHeader()
        });
  }
}

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "@env/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ActionAuthLogout, AppState, NotificationService } from "@app/core";
import { Store } from "@ngrx/store";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class SoftwareUpdatesService {
  baseURL: string = environment.baseURL;
  header: any;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private router: Router,
    private notification: NotificationService
  ) {}

  createHeader() {
    let hours = 1;
    let now = new Date().getTime();
    let setupTime = localStorage.getItem("setupTime");
    if (setupTime == null) {
      localStorage.setItem("setupTime", now.toString());
    } else {
      if (now - Number(setupTime) > hours * 60 * 60 * 1000) {
        this.notification.error("Your session has expired. Please login again");
        this.store.dispatch(new ActionAuthLogout());
        localStorage.clear();
        this.router.navigate(["login"]);
      }
    }
    return (this.header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token")
    }));
  }

  scheduleSoftwareUpdate(data): Observable<any> {
    return this.http.post(this.baseURL + "softwareUpdate/add", data, {
      headers: this.createHeader()
    });
  }

  getVersionList(data): Observable<any> {
    return this.http.get(
      this.baseURL +
        "version/fetch?&limit=" +
        data.limit +
        "&pageNumber=" +
        data.pageNo,
      {
        headers: this.createHeader()
      }
    );
  }

  getVersionById(id): Observable<any> {
    return this.http.get(this.baseURL + "version/fetch/" + id, {
      headers: this.createHeader()
    });
  }

  addVersion(data): Observable<any> {
    return this.http.post(this.baseURL + "version/add", data, {
      headers: this.createHeader()
    });
  }

  getDashboardRefreshToken(data): Observable<any> {
    return this.http.put(this.baseURL + "users/refreshToken", data, {
      headers: this.createHeader()
    });
  }

  editVersion(data, id): Observable<any> {
    return this.http.put(this.baseURL + "version/edit/" + id, data, {
      headers: this.createHeader()
    });
  }

  fetchVersions(data): Observable<any> {
    return this.http.get(
      this.baseURL +
        "version/fetch?&limit=" +
        data.limit +
        "&pageNumber=" +
        data.pageNumber +
        "&pattern=" +
        data.searchPattern +
        "&sort=" +
        data.sort,
      {
        headers: this.createHeader()
      }
    );
  }

  deleteVersion(id): Observable<any> {
    return this.http.delete(this.baseURL + "version/" + id, {
      headers: this.createHeader()
    });
  }

  getUploadUrl(data): Observable<any> {
    return this.http.put(this.baseURL + "version/upload/url", data, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    });
  }

  uploadFile(signedUrl, file): Observable<any> {
    return this.http.put(signedUrl, file, {});
  }
}

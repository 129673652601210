export class AppConstant {
  static LOGIN_SUCCESS = "Sign in successfully";
  static LOGOUT_SUCCESS = "Sign out successfully";
  static USER_ADD_SUCCESS = "User added successfully";
  static USER_EDIT_SUCCESS = "User updated successfully";
  static DISABLE_USER_SUCCESS = "User deactivated successfully";
  static UPDATE_USER_PASS_SUCCESS = "Password update successfully";
  static ENABLE_USER_SUCCESS = "User activated successfully";
  static SITE_ADD_SUCCESS = "Customer added successfully";
  static SITE_EDIT_SUCCESS = "Customer updated successfully";
  static DISABLE_SITE_SUCCESS = "Customer deactivated successfully";
  static RESEND_SITE_SUCCESS = "Resend status changed successfully";
  static ENABLE_SITE_SUCCESS = "Customer activated successfully";
  static LOCATION_ADD_SUCCESS = "Location added successfully";
  static LOCATION_EDIT_SUCCESS = "Location updated successfully";
  static DISABLE_LOCATION_SUCCESS = "Location deactivated successfully";
  static ENABLE_LOCATION_SUCCESS = "Location activated successfully";
  static DISABLE_REPORT_SUCCESS = "Report deactivated successfully";
  static ENABLE_REPORT_SUCCESS = "Report activated successfully";
  static DEVICE_EDIT_SUCCESS = "Device updated successfully";
  static LOCATION_SITE_SUCCESS = "Location changed successfully";
  static PERMISSION_ADD_SUCCESS = "Permissions updated successfully";
  static DEVICE_ADD_SUCCESS = "Device Added successfully";
  static DISABLE_DEVICE_SUCCESS = "Device deactivated successfully";
  static ENABLE_DEVICE_SUCCESS = "Device activated successfully";
  static ROLE_EDIT_SUCCESS = "Role updated successfully";
  static ROLE_ADD_SUCCESS = "Role Added successfully";
  static SETTINGS_EDIT_SUCCESS = "Settings updated successfully";
  static SETTINGS_SCHEDULE_SUCCESS =
    "Please note that the machine will get updated only if the machine is online";
  static CODE_SENT_SUCCESS = "Verification code sent successfully";
  static PASS_CHANGED_SUCCESS = "Password change successfully";
  static PASSWORD_SET_SUCCESS = "Password set successfully";
  static MACHINE_EDIT_SUCCESS = "Machine updated successfully";
  static MACHINE_ADD_SUCCESS = "Machine added successfully";
  static AREA_EDIT_SUCCESS = "Area updated successfully";
  static AREA_ADD_SUCCESS = "Area added successfully";
  static DISABLE_AREA_SUCCESS = "Area deactivated successfully";
  static ENABLE_AREA_SUCCESS = "Area activated successfully";
  static DISABLE_MACHINE_SUCCESS = "Machine deactivated successfully";
  static ENABLE_MACHINE_SUCCESS = "Machine activated successfully";
  static TEMPLATE_ADD_SUCCESS = "Template added successfully";
  static INTERNET_EMAIL_SUCCESS = "Notification send successfully";
  static TEMPLATE_EDIT_SUCCESS = "Template updated successfully";
  static DISABLE_TEMPLATE_SUCCESS = "Template deactivated successfully";
  static TEMPLATE_COPY_SUCCESS = "Template copied successfully";
  static TEMPLATE_APPLY_SUCCESS = "Template applied successfully";
  static SOFTWARE_APPLY_SUCCESS =
    "Please note that the machine will get updated only if the machine is online";
  static LICENSE_ADD_SUCCESS = "License added successfully";
  static LICENSE_UPDATE_SUCCESS = "License updated successfully";
  static LICENSE_APPLIED_SUCCESS = "License applied successfully";
  static File_UPLOAD_SUCCESS = "File Uploaded successfully";
  static File_UPLOAD_FAIL = "File Upload failed";
  static VERSION_ADD_SUCCESS = "Version added successfully";
  static VERSION_UPDATED_SUCCESS = "Version updated successfully";
  static VERSION_DELETED_SUCCESS = "Version deleted successfully";
  static FILE_FORMAT_ERROR =
    "Invalid File format. Please upload file in Zip format only";
  static DISABLE_APIKEY_SUCCESS = "API Key disabled successfully";
  static ENABLE_APIKEY_SUCCESS = "API Key enabled successfully";
  static DELETE_APIKEY_SUCCESS = "API Key deleted successfully";
  static REGENRATE_APIKEY_SUCCESS = "API Key regenerated successfully";
}

export class MachineSettingConstants {
  static ONSCREEN_LANGUAGE = [
    { type: "Arabic", value: false, name: "Arabic" },
    { type: "Hindi", value: false, name: "Hindi" },
    { type: "Spanish (Latin)", value: false, name: "SpanishLatin" },
    { type: "Chinese Simplified", value: false, name: "ChineseSimplified" },
    { type: "Italian", value: false, name: "Italian" },
    { type: "Swedish", value: false, name: "Swedish" },
    { type: "Chinese Traditional", value: false, name: "ChineseTraditional" },
    { type: "Korean", value: false, name: "Korean" },
    { type: "Swiss French", value: false, name: "SwissFrench" },
    { type: "English", value: false, name: "English" },
    { type: "Persian ", value: false, name: "Persian" },
    { type: "Swiss German", value: false, name: "SwissGerman" },
    { type: "French", value: false, name: "French" },
    { type: "Polish ", value: false, name: "Polish" },
    { type: "Swiss Italian", value: false, name: "SwissItalian" },
    { type: "French (Canadian)", value: false, name: "FrenchCanadian" },
    { type: "Portuguese (Brazil)", value: false, name: "BrazilPortuguese" },
    { type: "Tagalog", value: false, name: "Tagalog" },
    { type: "German", value: false, name: "German" },
    { type: "Portuguese", value: false, name: "Portuguese" },
    { type: "Vietnamese", value: false, name: "Vietnamese" },
    { type: "Greek", value: false, name: "Greek" },
    { type: "Russian", value: false, name: "Russian" },
    { type: "Hebrew", value: false, name: "Hebrew" },
    { type: "Spanish", value: false, name: "Spanish" }
  ];

  static CLOUDSOURCE_MEDIA = [
    { value: false, name: "Usb" },
    { value: false, name: "Email" },
    { value: false, name: "Print" },
    { value: false, name: "GoogleDrive" },
    { value: false, name: "Smartphone" },
    { value: false, name: "Fax" },
    { value: false, name: "Network" },
    { value: false, name: "Ftp" },
    { value: false, name: "Dropbox" },
    { value: false, name: "OneDrive" },
    { value: false, name: "Audio" },
    { value: false, name: "Translation" },
    { value: false, name: "Box" },
    { value: false, name: "Restore" }
  ];

  static ABBYYLIST = [
    { type: "Africaans", value: false, name: "Afrikaans" },
    { type: "Chinese (Taiwan)", value: false, name: "ChineseTaiwan" },
    { type: "English", value: false, name: "English" },
    { type: "Arabic", value: false, name: "Arabic" },
    { type: "Croatian", value: false, name: "Croatian" },
    { type: "Estonian", value: false, name: "Estonian" },
    { type: "Bulgarian", value: false, name: "Bulgarian" },
    { type: "Czech", value: false, name: "Czech" },
    { type: "Fijian", value: false, name: "Fijian" },
    { type: "German (Luxembourg)", value: false, name: "GermanLuxemburg" },
    { type: "Catalan", value: false, name: "Catalan" },
    { type: "Danish", value: false, name: "Danish" },
    { type: "Finnish", value: false, name: "Finnish" },
    { type: "Greek", value: false, name: "Greek" },
    { type: "Chinese (PRC)", value: false, name: "ChinesePRC" },
    { type: "Dutch (Belgium)", value: false, name: "DutchBelgium" },
    { type: "French", value: false, name: "French" },
    { type: "Hebrew", value: false, name: "Hebrew" },
    { type: "Hungarian", value: false, name: "Hungarian" },
    { type: "Maltese", value: false, name: "Maltese" },
    { type: "Slovak", value: false, name: "Slovak" },
    { type: "Indonesian", value: false, name: "Indonesian" },
    { type: "Norwegian (Bokmal)", value: false, name: "NorwegianBokmal" },
    { type: "Slovenian", value: false, name: "Slovenian" },
    { type: "Italian", value: false, name: "Italian" },
    { type: "Polish", value: false, name: "Polish" },
    { type: "Spanish", value: false, name: "Spanish" },
    { type: "Japanese", value: false, name: "Japanese" },
    {
      type: "Portuguese (Brazilian)",
      value: false,
      name: "PortugueseBrazilian"
    },
    { type: "Swedish", value: false, name: "Swedish" },
    { type: "Korean", value: false, name: "Korean" },
    { type: "Romanian", value: false, name: "Romanian" },
    { type: "Tahitian", value: false, name: "Tahitian" },
    { type: "Latvian", value: false, name: "Latvian" },
    { type: "Russian", value: false, name: "Russian" },
    { type: "Thai", value: false, name: "Thai" },
    { type: "Lithuanian", value: false, name: "Lithuanian" },
    { type: "Samoan ", value: false, name: "Samoan" },
    { type: "Tongan", value: false, name: "Tongan" },
    { type: "Malagasy", value: false, name: "Malagasy" },
    { type: "Serbian (Cyrillic)", value: false, name: "SerbianCyrillic" },
    { type: "Turkish", value: false, name: "Turkish" },
    { type: "Malay", value: false, name: "Malay" },
    { type: "Serbian (Latin)", value: false, name: "SerbianLatin" },
    { type: "Ukrainian", value: false, name: "Ukrainian" },
    { type: "Vietnamese", value: false, name: "Vietnamese" },
    { type: "Welsh", value: false, name: "Welsh" }
  ];

  static OMNILIST = [
    { type: "Africaans", value: false, name: "Afrikaans" },
    { type: "Arabic", value: false, name: "Arabic" },
    { type: "Bulgarian", value: false, name: "Bulgarian" },
    { type: "Catalan", value: false, name: "Catalan" },
    { type: "Chinese (PRC)", value: false, name: "ChinesePRC" },
    { type: "Chinese (Taiwan)", value: false, name: "ChineseTaiwan" },
    { type: "Croatian", value: false, name: "Croatian" },
    { type: "Czech", value: false, name: "Czech" },
    { type: "Danish", value: false, name: "Danish" },
    { type: "Dutch (Belgium)", value: false, name: "DutchBelgium" },
    { type: "English", value: false, name: "English" },
    { type: "Estonian", value: false, name: "Estonian" },
    { type: "Fijian", value: false, name: "Fijian" },
    { type: "Finnish", value: false, name: "Finnish" },
    { type: "French", value: false, name: "French" },
    { type: "German (Luxembourg)", value: false, name: "GermanLuxemburg" },
    { type: "Greek", value: false, name: "Greek" },
    { type: "Hebrew", value: false, name: "Hebrew" },
    { type: "Hungarian", value: false, name: "Hungarian" },
    { type: "Indonesian", value: false, name: "Indonesian" },
    { type: "Italian", value: false, name: "Italian" },
    { type: "Japanese", value: false, name: "Japanese" },
    { type: "Korean", value: false, name: "Korean" },
    { type: "Latvian", value: false, name: "Latvian" },
    { type: "Lithuanian", value: false, name: "Lithuanian" },
    { type: "Malagasy", value: false, name: "Malagasy" },
    { type: "Malay", value: false, name: "Malay" },
    { type: "Maltese", value: false, name: "Maltese" },
    { type: "Norwegian (Bokmal)", value: false, name: "NorwegianBokmal" },
    { type: "Polish", value: false, name: "Polish" },
    {
      type: "Portuguese (Brazilian)",
      value: false,
      name: "PortugueseBrazilian"
    },
    { type: "Romanian", value: false, name: "Romanian" },
    { type: "Russian", value: false, name: "Russian" },
    { type: "Samoan ", value: false, name: "Samoan" },
    { type: "Serbian (Cyrillic)", value: false, name: "SerbianCyrillic" },
    { type: "Serbian (Latin)", value: false, name: "SerbianLatin" },
    { type: "Slovak", value: false, name: "Slovak" },
    { type: "Slovenian", value: false, name: "Slovenian" },
    { type: "Spanish", value: false, name: "Spanish" },
    { type: "Swedish", value: false, name: "Swedish" },
    { type: "Tahitian", value: false, name: "Tahitian" },
    { type: "Thai", value: false, name: "Thai" },
    { type: "Tongan", value: false, name: "Tongan" },
    { type: "Turkish", value: false, name: "Turkish" },
    { type: "Ukrainian", value: false, name: "Ukrainian" },
    { type: "Vietnamese", value: false, name: "Vietnamese" },
    { type: "Welsh", value: false, name: "Welsh" }
  ];

  static MICROSOFTLANGUAGESTRANSLATION = [
    { type: "Afrikaans", value: false, name: "Afrikaans" },
    { type: "Albanian", value: false, name: "Albanian" },
    { type: "Armenian", value: false, name: "Armenian" },
    { type: "Amharic", value: false, name: "Amharic" },
    { type: "Assamese", value: false, name: "Assamese" },
    { type: "Azerbaijani", value: false, name: "Azerbaijani" },
    { type: "Bashkir", value: false, name: "Bashkir" },
    { type: "Czech", value: false, name: "Czech" },
    { type: "Dari", value: false, name: "Dari" },
    { type: "Divehi", value: false, name: "Divehi" },
    { type: "Georgian", value: false, name: "Georgian" },
    { type: "Greek", value: false, name: "Greek" },
    { type: "Gujarati", value: false, name: "Gujarati" },
    { type: "Inuktitut", value: false, name: "Inuktitut" },
    { type: "Irish", value: false, name: "Irish" },
    { type: "Kannada", value: false, name: "Kannada" },
    { type: "Kazakh", value: false, name: "Kazakh" },
    { type: "Khmer", value: false, name: "Khmer" },
    { type: "Kiswahili", value: false, name: "Kiswahili" },
    { type: "Arabic", value: false, name: "Arabic" },
    { type: "Danish", value: false, name: "Danish" },
    { type: "Haitian Creole", value: false, name: "HaitianCreole" },
    { type: "Klingon", value: false, name: "Klingon" },
    { type: "Kurdish Central", value: false, name: "Kurdish_Central" },
    { type: "Kurdish Northern", value: false, name: "Kurdish_Northern" },
    { type: "Kyrgyz", value: false, name: "Kyrgyz" },
    { type: "Lao", value: false, name: "Lao" },
    { type: "Macedonian", value: false, name: "Macedonian" },
    { type: "Malayalam", value: false, name: "Malayalam" },
    { type: "Maori", value: false, name: "Maori" },
    { type: "Marathi", value: false, name: "Marathi" },
    { type: "Mongolian Cyrillic", value: false, name: "Mongolian_Cyrillic" },
    { type: "Myanmar", value: false, name: "Myanmar" },
    { type: "Nepali", value: false, name: "Nepali" },
    { type: "Odia", value: false, name: "Odia" },
    { type: "Pashto", value: false, name: "Pashto" },
    { type: "Punjabi", value: false, name: "Punjabi" },
    { type: "Swahili", value: false, name: "Swahili" },
    { type: "Tatar", value: false, name: "Tatar" },
    { type: "Tibetan", value: false, name: "Tibetan" },
    { type: "Tigrinya", value: false, name: "Tigrinya" },
    { type: "Turkmen", value: false, name: "Turkmen" },
    { type: "Uyghur", value: false, name: "Uyghur" },
    { type: "Uzbek", value: false, name: "Uzbek" },
    { type: "Bangla", value: false, name: "Bangla" },
    { type: "Dutch", value: false, name: "Dutch" },
    { type: "Hebrew", value: false, name: "Hebrew" },
    { type: "Klingon plqaD", value: false, name: "Klingon_pIqaD" },
    { type: "Bosnian", value: false, name: "Bosnian" },
    { type: "English", value: false, name: "English" },
    { type: "Hindi", value: false, name: "Hindi" },
    { type: "Korea", value: false, name: "Korea" },
    { type: "Bulgarian", value: false, name: "Bulgarian" },
    { type: "Estonian", value: false, name: "Estonian" },
    { type: "HmongDaw", value: false, name: "HmongDaw" },
    { type: "Latvian", value: false, name: "Latvian" },
    { type: "Cantonese", value: false, name: "Cantonese" },
    { type: "Fijian", value: false, name: "Fijian" },
    { type: "Hungarian", value: false, name: "Hungarian" },
    { type: "Lithuanian", value: false, name: "Lithuanian" },
    { type: "Catalan", value: false, name: "Catalan" },
    { type: "Filipino", value: false, name: "Filipino" },
    { type: "Icelandic", value: false, name: "Icelandic" },
    { type: "Malagasy", value: false, name: "Malagasy" },
    { type: "Chinese Simplified", value: false, name: "Chinese" },
    { type: "Finnish", value: false, name: "Finnish" },
    { type: "Indonesian", value: false, name: "Indonesian" },
    { type: "Malay", value: false, name: "Malay" },
    { type: "Chinese Traditional", value: false, name: "ChineseTraditional" },
    { type: "French", value: false, name: "French" },
    { type: "Italian", value: false, name: "Italian" },
    { type: "Maltese", value: false, name: "Maltese" },
    { type: "Croatian", value: false, name: "Croatian" },
    { type: "German", value: false, name: "German" },
    { type: "Japanese", value: false, name: "Japanese" },
    { type: "Norwegian", value: false, name: "Norwegian" },
    { type: "Slovak", value: false, name: "Slovak" },
    { type: "Ukrainian", value: false, name: "Ukrainian" },
    { type: "Persian", value: false, name: "Persian" },
    { type: "Slovenian", value: false, name: "Slovenian" },
    { type: "Urdu", value: false, name: "Urdu" },
    { type: "Polish", value: false, name: "Polish" },
    { type: "Spanish", value: false, name: "Spanish" },
    { type: "Vietnamese", value: false, name: "Vietnamese" },
    { type: "Portuguese", value: false, name: "Portuguese" },
    { type: "Swedish", value: false, name: "Swedish" },
    { type: "Welsh", value: false, name: "Welsh" },
    { type: "Queretaro Otomi", value: false, name: "QO" },
    { type: "Tamil", value: false, name: "Tamil" },
    { type: "Yucatec Maya", value: false, name: "YucatecMaya" },
    { type: "Romanian", value: false, name: "Romanian" },
    { type: "Tahitian", value: false, name: "Tahitian" },
    { type: "Russian", value: false, name: "Russian" },
    { type: "Telugu", value: false, name: "Telugu" },
    { type: "Samoan", value: false, name: "Samoan" },
    { type: "Thai", value: false, name: "Thai" },
    { type: "Serbian Cyrillic", value: false, name: "SerbianCyrillic" },
    { type: "Tongan", value: false, name: "Tongan" },
    { type: "Serbian Latin", value: false, name: "SerbianLatin" },
    { type: "Turkish", value: false, name: "Turkish" }
  ];

  static GOOGLELANGUAGESTRANSLATION = [
    { type: "Afrikaans", value: false, name: "Afrikaans" },
    { type: "Arabic", value: false, name: "Arabic" },
    { type: "Albanian", value: false, name: "Albanian" },
    { type: "Amharic", value: false, name: "Amharic" },
    { type: "Armenian", value: false, name: "Armenian" },
    { type: "Azerbaijani", value: false, name: "Azerbaijani" },
    { type: "Basque", value: false, name: "Basque" },
    { type: "Belarusian", value: false, name: "Belarusian" },
    { type: "Bengali", value: false, name: "Bengali" },
    { type: "Bosnian", value: false, name: "Bosnian" },
    { type: "Bulgarian", value: false, name: "Bulgarian" },
    { type: "Cantonese", value: false, name: "Cantonese" },
    { type: "Catalan", value: false, name: "Catalan" },
    { type: "Cebuano", value: false, name: "Cebuano" },
    { type: "Chinese Simplified", value: false, name: "Chinese" },
    { type: "ChineseTraditional", value: false, name: "ChineseTraditional" },
    { type: "Corsican", value: false, name: "Corsican" },
    { type: "Croatian", value: false, name: "Croatian" },
    { type: "Czech", value: false, name: "Czech" },
    { type: "Danish", value: false, name: "Danish" },
    { type: "Dutch", value: false, name: "Dutch" },
    { type: "English", value: false, name: "English" },
    { type: "Esperanto", value: false, name: "Esperanto" },
    { type: "Estonian", value: false, name: "Estonian" },
    { type: "Fijian", value: false, name: "Fijian" },
    { type: "Filipino", value: false, name: "Filipino" },
    { type: "Finnish", value: false, name: "Finnish" },
    { type: "French", value: false, name: "French" },
    { type: "Frisian", value: false, name: "Frisian" },
    { type: "Galician", value: false, name: "Galician" },
    { type: "Georgian", value: false, name: "Georgian" },
    { type: "German", value: false, name: "German" },
    { type: "Greek", value: false, name: "Greek" },
    { type: "Gujarati", value: false, name: "Gujarati" },
    { type: "Haitian Creole", value: false, name: "HaitianCreole" },
    { type: "Hausa", value: false, name: "Hausa" },
    { type: "Hawaiian", value: false, name: "Hawaiian" },
    { type: "Hebrew", value: false, name: "Hebrew" },
    { type: "Hindi", value: false, name: "Hindi" },
    { type: "Hmong Daw", value: false, name: "HmongDaw" },
    { type: "Hungarian", value: false, name: "Hungarian" },
    { type: "Icelandic", value: false, name: "Icelandic" },
    { type: "Igbo", value: false, name: "Igbo" },
    { type: "Indonesian", value: false, name: "Indonesian" },
    { type: "Irish", value: false, name: "Irish" },
    { type: "Italian", value: false, name: "Italian" },
    { type: "Japanese", value: false, name: "Japanese" },
    { type: "Javanese", value: false, name: "Javanese" },
    { type: "Kannada", value: false, name: "Kannada" },
    { type: "Kazakh", value: false, name: "Kazakh" },
    { type: "Khmer", value: false, name: "Khmer" },
    { type: "Kiswahili", value: false, name: "Kiswahili" },
    { type: "Klingon", value: false, name: "Klingon" },
    { type: "Klingon plqaD", value: false, name: "Klingon_pIqaD" },
    { type: "Korea", value: false, name: "Korea" },
    { type: "Kurdish", value: false, name: "Kurdish" },
    { type: "Kyrgyz", value: false, name: "Kyrgyz" },
    { type: "Lao", value: false, name: "Lao" },
    { type: "Latin", value: false, name: "Latin" },
    { type: "Latvian", value: false, name: "Latvian" },
    { type: "Lithuanian", value: false, name: "Lithuanian" },
    { type: "Luxembourgish", value: false, name: "Luxembourgish" },
    { type: "Macedonian", value: false, name: "Macedonian" },
    { type: "Malagasy", value: false, name: "Malagasy" },
    { type: "Malay", value: false, name: "Malay" },
    { type: "Malayalam", value: false, name: "Malayalam" },
    { type: "Maltese", value: false, name: "Maltese" },
    { type: "Maori", value: false, name: "Maori" },
    { type: "Marathi", value: false, name: "Marathi" },
    { type: "Mongolian", value: false, name: "Mongolian" },
    { type: "Myanmar", value: false, name: "Myanmar" },
    { type: "Nepali", value: false, name: "Nepali" },
    { type: "Norwegian", value: false, name: "Norwegian" },
    { type: "Nyanja", value: false, name: "Nyanja" },
    { type: "Pashto", value: false, name: "Pashto" },
    { type: "Persian", value: false, name: "Persian" },
    { type: "Polish", value: false, name: "Polish" },
    { type: "Portuguese", value: false, name: "Portuguese" },
    { type: "Punjabi", value: false, name: "Punjabi" },
    { type: "Queretaro Otomi", value: false, name: "QO" },
    { type: "Romanian", value: false, name: "Romanian" },
    { type: "Russian", value: false, name: "Russian" },
    { type: "Samoan", value: false, name: "Samoan" },
    { type: "Scots Gaelic", value: false, name: "ScotsGaelic" },
    { type: "Serbian", value: false, name: "Serbian" },
    { type: "Serbian Cyrillic", value: false, name: "SerbianCyrillic" },
    { type: "Serbian Latin", value: false, name: "SerbianLatin" },
    { type: "Sesotho", value: false, name: "Sesotho" },
    { type: "Shona", value: false, name: "Shona" },
    { type: "Sindhi", value: false, name: "Sindhi" },
    { type: "Sinhala", value: false, name: "Sinhala" },
    { type: "Slovak", value: false, name: "Slovak" },
    { type: "Slovenian", value: false, name: "Slovenian" },
    { type: "Somali", value: false, name: "Somali" },
    { type: "Spanish", value: false, name: "Spanish" },
    { type: "Sundanese", value: false, name: "Sundanese" },
    { type: "Swahili", value: false, name: "Swahili" },
    { type: "Swedish", value: false, name: "Swedish" },
    { type: "Tagalog", value: false, name: "Tagalog" },
    { type: "Tahitian", value: false, name: "Tahitian" },
    { type: "Tajik", value: false, name: "Tajik" },
    { type: "Tamil", value: false, name: "Tamil" },
    { type: "Telugu", value: false, name: "Telugu" },
    { type: "Thai", value: false, name: "Thai" },
    { type: "Tongan", value: false, name: "Tongan" },
    { type: "Turkish", value: false, name: "Turkish" },
    { type: "Ukrainian", value: false, name: "Ukrainian" },
    { type: "Urdu", value: false, name: "Urdu" },
    { type: "Uzbek", value: false, name: "Uzbek" },
    { type: "Vietnamese", value: false, name: "Vietnamese" },
    { type: "Welsh", value: false, name: "Welsh" },
    { type: "Xhosa", value: false, name: "Xhosa" },
    { type: "Yiddish", value: false, name: "Yiddish" },
    { type: "Yoruba", value: false, name: "Yoruba" },
    { type: "Yucatec Maya", value: false, name: "YucatecMaya" },
    { type: "Zulu", value: false, name: "Zulu" }
  ];

  static MICROSOFTLANGUAGESAUDIO = [
    { type: "Arabic", value: false, name: "Arabic" },
    { type: "Greek", value: false, name: "Greek" },
    { type: "Indonesian", value: false, name: "Indonesian" },
    { type: "Slovak", value: false, name: "Slovak" },
    { type: "Bulgarian", value: false, name: "Bulgarian" },
    { type: "English", value: false, name: "English" },
    { type: "Japanese", value: false, name: "Japanese" },
    { type: "Slovenian", value: false, name: "Slovenian" },
    { type: "Catalan", value: false, name: "Catalan" },
    { type: "Finnish", value: false, name: "Finnish" },
    { type: "Korea", value: false, name: "Korea" },
    { type: "Spanish", value: false, name: "Spanish" },
    { type: "Chinese Simplified", value: false, name: "ChineseSimplified" },
    { type: "French", value: false, name: "French" },
    { type: "Malay", value: false, name: "Malay" },
    { type: "Swedish", value: false, name: "Swedish" },
    { type: "Chinese Traditional", value: false, name: "ChineseTraditional" },
    { type: "German", value: false, name: "German" },
    { type: "Norwegian", value: false, name: "Norwegian" },
    { type: "Tamil", value: false, name: "Tamil" },
    { type: "Croatian", value: false, name: "Croatian" },
    { type: "Hebrew", value: false, name: "Hebrew" },
    { type: "Polish", value: false, name: "Polish" },
    { type: "Thai", value: false, name: "Thai" },
    { type: "Czech", value: false, name: "Czech" },
    { type: "Hindi", value: false, name: "Hindi" },
    { type: "Portuguese", value: false, name: "Portuguese" },
    { type: "Vietnamese", value: false, name: "Vietnamese" },
    { type: "Danish", value: false, name: "Danish" },
    { type: "Hungarian", value: false, name: "Hungarian" },
    { type: "Romanian", value: false, name: "Romanian" },
    { type: "Dutch", value: false, name: "Dutch" },
    { type: "Italian", value: false, name: "Italian" },
    { type: "Russian", value: false, name: "Russian" }
  ];

  static GOOGLELANGUAGESAUDIO = [
    { type: "Afrikaans", value: false, name: "Afrikaans" },
    { type: "Amharic", value: false, name: "Amharic" },
    { type: "Bangla", value: false, name: "Bangla" },
    { type: "Burmese", value: false, name: "Burmese" },
    { type: "Danish", value: false, name: "Danish" },
    { type: "Dutch", value: false, name: "Dutch" },
    { type: "English", value: false, name: "English" },
    { type: "Estonian", value: false, name: "Estonian" },
    { type: "Filipino", value: false, name: "Filipino" },
    { type: "French", value: false, name: "French" },
    { type: "German", value: false, name: "German" },
    { type: "Irish", value: false, name: "Irish" },
    { type: "Italian", value: false, name: "Italian" },
    { type: "Japanese", value: false, name: "Japanese" },
    { type: "Khmer", value: false, name: "Khmer" },
    { type: "Korea", value: false, name: "Korea" },
    { type: "Latvian", value: false, name: "Latvian" },
    { type: "Lithuanian", value: false, name: "Lithuanian" },
    { type: "Maltese", value: false, name: "Maltese" },
    { type: "Marathi", value: false, name: "Marathi" },
    { type: "Persian", value: false, name: "Persian" },
    { type: "Polish", value: false, name: "Polish" },
    { type: "Portuguese", value: false, name: "Portuguese" },
    { type: "Russian", value: false, name: "Russian" },
    { type: "Slovak", value: false, name: "Slovak" },
    { type: "Spaish", value: false, name: "Spaish" },
    { type: "Swahili", value: false, name: "Swahili" },
    { type: "Swedish", value: false, name: "Swedish" },
    { type: "Turkish", value: false, name: "Turkish" },
    { type: "Ukrainian", value: false, name: "Ukrainian" },
    { type: "Urdu", value: false, name: "Urdu" },
    { type: "Uzbek", value: false, name: "Uzbek" },
    { type: "Welsh", value: false, name: "Welsh" }
  ];

  static SETTINGSLIST = [
    {
      name: "General",
      icon: "fa fa-gear",
      childTab: [
        { name: "System" },
        { name: "Limit" },
        { name: "Abbyy" },
        { name: "OmniPage" },
        { name: "Onscreen Language" }
      ]
    },
    {
      name: "Features",
      icon: "fa fa-bookmark",
      childTab: [
        { name: "Customs" },
        { name: "Cloud Source" },
        { name: "General" },
        { name: "Options" },
        { name: "Color" },
        { name: "Resolution" },
        { name: "Copyright" },
        { name: "Scan Copyright" }
      ]
    },
    {
      name: "Configuration",
      icon: "fa fa-empire",
      childTab: [
        { name: "USB" },
        { name: "Email" },
        { name: "Print" },
        { name: "Copy" },
        { name: "PPL" },
        { name: "Google Drive" },
        { name: "One Drive" },
        { name: "Dropbox" },
        { name: "Smartphone" },
        { name: "Network" },
        { name: "Fax" },
        { name: "FTP" },
        { name: "Translation" },
        { name: "Audio" },
        { name: "Illiad" },
        { name: "Restore" },
        { name: "Box" }
      ]
    },
    {
      name: "Payment",
      icon: "fa fa-credit-card",
      childTab: [
        { name: "General" },
        { name: "Pricing" },
        { name: "CBord" },
        { name: "Papercut" },
        { name: "Monitor" },
        { name: "Nayax" },
        { name: "SAM" },
        { name: "9900" }
      ]
    },
    {
      name: "Log",
      icon: "fa fa-history",
      childTab: [
        { name: "Log Settings" }
        // { name: "Application" },
        // { name: "System" },
        // { name: "Update" }
      ]
    },
    {
      name: "Display",
      icon: "fa fa-desktop",
      childTab: [
        { name: "General" },
        { name: "License" },
        { name: "EULA" },
        { name: "Button" },
        { name: "Message" },
        { name: "Page" }
      ]
    }
  ];

  static STATUSLIST = [
    { index: 1, name: "All" },
    { index: 2, name: "Active" },
    { index: 3, name: "Inactive" }
  ];

  static EMAILLIST = [
    { value: 1, name: "GoogleAuth" },
    { value: 2, name: "MicrosoftAuth" }
  ];

  static IMAGEQUALITYLIST = [
    { value: 1, name: "Pdf" },
    { value: 2, name: "Spdf" },
    { value: 3, name: "Word" },
    { value: 4, name: "Tiff" },
    { value: 5, name: "Jpeg" },
    { value: 6, name: "Png" },
    { value: 7, name: "Html" }
  ];
}

import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import {Store} from "@ngrx/store";
import {ActionAuthLogout, AppState, NotificationService} from "@app/core";
import {Router} from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class UsersService {
  baseURL: string = environment.baseURL;
  header: any;

  constructor(private http: HttpClient,
              private store: Store<AppState>,
              private router: Router,
              private notification: NotificationService) {}

  /*
    Method to set the header in the API calls of this file
  */
  createHeader() {
    let hours = 1;
    let now = new Date().getTime();
    let setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {
      localStorage.setItem('setupTime', now.toString())
    } else {
      if ( now - Number(setupTime) > hours*60*60*1000) {
        this.notification.error('Your session has expired. Please login again');
        this.store.dispatch(new ActionAuthLogout());
        localStorage.clear();
        this.router.navigate(["login"]);
      }
    }
    return (this.header = new HttpHeaders({
      // api_key: environment.api_key,
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token")
    }));
  }

  /*
    API call for getting all the users list present in the database of this application
  */
  getAllUsersList(data, reqToken): Observable<any> {
    return this.http.put(
      this.baseURL + "users/getUsers?&limit=" + data.limit + "&status=" +
        data.status +
        "&pattern=" +
        data.searchPattern, {'paginationToken' : reqToken},
      {
        headers: this.createHeader()
      }
    );
  }


  getUpdatePassword(userName, password): Observable<any> {
    return this.http.put(
        this.baseURL + 'users/adminChangePassword', {"password": password,
          "userName": userName},
        {
          headers: this.createHeader()
        }
    );
  }

  /*
    API call for adding a new user
  */
  addUser(data): Observable<any> {
    return this.http.post(this.baseURL + "users/addUser", data, {
      headers: this.createHeader()
    });
  }

  /*
    API call for getting particular user info by userID/username
  */
  getUserDetailsByID(data): Observable<any> {
    return this.http.get(this.baseURL + "users/fetchUser/" + data.userName, {
      headers: this.createHeader()
    });
  }

  /*
    API call for editing the user and submit the latest information
  */
  editUser(username, data): Observable<any> {
    return this.http.put(this.baseURL + "users/editUser/" + username, data, {
      headers: this.createHeader()
    });
  }

  /*
    API call for enabling the user by username
  */
  deleteUser(username, data): Observable<any> {
    return this.http.put(
      this.baseURL + "users/enableOrDisableUser/" + username,
      data,
      {
        headers: this.createHeader()
      }
    );
  }

  getUserLogs(data): Observable<any> {
    return this.http.put(this.baseURL + "userLog/fetch?&limit", data, {
      headers: this.createHeader()
    });
  }

  // getUserLogs(data, logs): Observable<any> {
  //   return this.http.put(
  //       this.baseURL + "userLog/fetch?&limit=" + data.limit + "&status=" +
  //       data.status + "&pageNumber=" + data.pageNumber + "&sort=" + data.sort + "&dateTo=" + data.dateTo + "&dateFrom=" + data.dateFrom + "&timeZone=" + data.timezone +
  //       "&pattern=" +
  //       data.searchPattern, {logs},
  //       {
  //         headers: this.createHeader()
  //       }
  //   );
  // }

  getDashboardRefreshToken(data): Observable<any> {
    return this.http.put(
        this.baseURL + 'users/refreshToken', data, {
          headers: this.createHeader()
        });
  }

}
